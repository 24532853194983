// #####  Color Palette by Paletton.com
// #####  Palette URL: http://paletton.com/#uid=73r2k0krqQP1sTXhuO+TIUHZNsI

// *** Primary color:

//    shade 0 = #29A9E1 = rgb( 41,169,225) = rgba( 41,169,225,1) = rgb0(0.161,0.663,0.882)
//    shade 1 = #E0E7EA = rgb(224,231,234) = rgba(224,231,234,1) = rgb0(0.878,0.906,0.918)
//    shade 2 = #69B9DC = rgb(105,185,220) = rgba(105,185,220,1) = rgb0(0.412,0.725,0.863)
//    shade 3 = #02A6EC = rgb(  2,166,236) = rgba(  2,166,236,1) = rgb0(0.008,0.651,0.925)
//    shade 4 = #006590 = rgb(  0,101,144) = rgba(  0,101,144,1) = rgb0(0,0.396,0.565)

// *** Secondary color (1):

//    shade 0 = #FF4625 = rgb(255, 70, 37) = rgba(255, 70, 37,1) = rgb0(1,0.275,0.145)
//    shade 1 = #FFF5F4 = rgb(255,245,244) = rgba(255,245,244,1) = rgb0(1,0.961,0.957)
//    shade 2 = #FF8974 = rgb(255,137,116) = rgba(255,137,116,1) = rgb0(1,0.537,0.455)
//    shade 3 = #FF2800 = rgb(255, 40,  0) = rgba(255, 40,  0,1) = rgb0(1,0.157,0)
//    shade 4 = #E52400 = rgb(229, 36,  0) = rgba(229, 36,  0,1) = rgb0(0.898,0.141,0)

// *** Secondary color (2):

//    shade 0 = #21E965 = rgb( 33,233,101) = rgba( 33,233,101,1) = rgb0(0.129,0.914,0.396)
//    shade 1 = #E5F0E9 = rgb(229,240,233) = rgba(229,240,233,1) = rgb0(0.898,0.941,0.914)
//    shade 2 = #68E593 = rgb(104,229,147) = rgba(104,229,147,1) = rgb0(0.408,0.898,0.576)
//    shade 3 = #00F252 = rgb(  0,242, 82) = rgba(  0,242, 82,1) = rgb0(0,0.949,0.322)
//    shade 4 = #00A839 = rgb(  0,168, 57) = rgba(  0,168, 57,1) = rgb0(0,0.659,0.224)

// *** Complement color:

//    shade 0 = #FF9825 = rgb(255,152, 37) = rgba(255,152, 37,1) = rgb0(1,0.596,0.145)
//    shade 1 = #FFFAF4 = rgb(255,250,244) = rgba(255,250,244,1) = rgb0(1,0.98,0.957)
//    shade 2 = #FFBD74 = rgb(255,189,116) = rgba(255,189,116,1) = rgb0(1,0.741,0.455)
//    shade 3 = #FF8700 = rgb(255,135,  0) = rgba(255,135,  0,1) = rgb0(1,0.529,0)
//    shade 4 = #E57900 = rgb(229,121,  0) = rgba(229,121,  0,1) = rgb0(0.898,0.475,0)

// #####  Generated by Paletton.com (c) 2002-2014

@mixin implementVariables(
  $prefix,
  $vals
) {
  @each $val in $vals {
    --#{$prefix}-#{nth($val, 1)}: #{nth(
        $val,
        2
      )};
  }
}

$colors: 'primary' #1da57a,
  'primary-0' #1da57a,
  'primary-1' #e0e7ea,
  'primary-2' #69b9dc,
  'primary-3' #0299c7,
  'primary-4' #0388b7;

@mixin implementVariablesClass(
  $prefix,
  $vals
) {
  @each $val in $vals {
    .#{$prefix}-#{nth($val, 1)} {
      #{$prefix}: #{nth($val, 2)};
    }
  }
}

body {
  @include implementVariables(
    color,
    $colors
  );
}
@include implementVariablesClass(
  color,
  $colors
);
@include implementVariablesClass(
  background,
  $colors
);
