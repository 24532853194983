.draftJsToolbar_inline___buttonWrapper {
  display: flex;
}

.draftJsToolbar_inline___button {
  background: var(--background);
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
}

.draftJsToolbar_inline___button svg {
  fill: #888;
}

.draftJsToolbar_inline___button:hover,
.draftJsToolbar_inline___button:focus {
  background: var(--background-200);
  outline: 0; /* reset for :focus */
}

.draftJsToolbar_inline___active {
  color: var(--color-primary);
}

.draftJsToolbar_inline___active svg {
  fill: var(--color-primary);
}
.draftJsToolbar_inline___separator {
  display: inline-block;
  border-right: 1px solid #ddd;
  height: 24px;
  margin: 0 0.5em;
}
.draftJsToolbar_inline___toolbar {
  padding: 0.2em;
  display: flex;
  align-items: center;
  left: 50%;
  -webkit-transform: translate(-50%)
    scale(0);
  transform: translate(-50%) scale(0);
  position: absolute;
  border: 1px solid #ddd;
  background: var(--background);
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px
    rgba(220, 220, 220, 1);
  z-index: 2;
  box-sizing: border-box;
}

.draftJsToolbar_inline___toolbar:after,
.draftJsToolbar_inline___toolbar:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.draftJsToolbar_inline___toolbar:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 4px;
  margin-left: -4px;
}
.draftJsToolbar_inline___toolbar:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #ddd;
  border-width: 6px;
  margin-left: -6px;
}
