.FeedCard {
  position: relative;
  word-break: break-word;
}

.FeedCardContent {
  background-color: var(--background);
  position: relative;
  width: 100%;
  border-radius: 0.3em;
  border-width: 1px;
  --border-opacity: 0.3;
}
.FeedCardFooter {
  border-radius: 0 0 0.3em 0.3em;
}
.FeedCardContent > * {
  padding: 0 0.5rem;
}
.FeedCardContent:hover {
  /* border-color: #cecece; */
  background-color: var(
    --background-100
  );
}
.FeedCardContent:hover .extraIcon {
  visibility: visible;
}
.mobile .FeedCardContent .extraIcon {
  visibility: visible;
}
.FeedCardContent .extraIcon {
  z-index: 1;
  width: 32px;
  height: 32px;
  display: flex;

  justify-content: center;
  align-items: center;
  visibility: hidden;
  position: absolute;
  top: 0.1em;
  right: 0.1em;
  line-height: 1;
}
.FeedCount {
  /* position: relative; */
  /* font-size: 1.1em; */
  font-weight: 400;
}
.FeedCardFooter {
  margin-top: 0 !important;
}
