.feedinModal {
  /* position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
  height: 100%;
  top: 0;
  max-width: unset !important; */
  /* max-height: unset !important; */
}
.feedinModal .ant-modal-content {
  max-width: 600px;
  width: 100%;
  max-height: calc(
    100vh - 100px - 2rem
  );
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.feedinModal .ant-modal-close-icon {
  padding: 0.4em;
  color: var(--text-color);
}
.feedinModal
  .ant-modal-content
  .ant-modal-body {
  overflow: auto;
  padding: 0 1.2rem;
}

.feedinModal
.ant-modal-content
.ant-modal-body::-webkit-scrollbar {
    width: 8px;
}

.feedinModal
.ant-modal-content
.ant-modal-body::-webkit-scrollbar-track {
    background-color: rgba(233, 233, 233, 0.5);
}

.feedinModal
.ant-modal-content
.ant-modal-body::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border: 0.5px solid transparent;
    border-radius: 5px;
    background-clip: content-box;
}
@media (max-width: 767px) {
  .feedinModal.ant-modal {
    /* position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    margin: 0;
    padding: 0; */
  }
  .feedinModal .ant-modal-content {
    /* max-width: calc(100vw - 1rem);
    max-height: calc(100vh - 1rem); */
  }
}
