@import 'variables';
// html {
//   // scroll-behavior: smooth;
//   filter: invert(100%);
// }
// img,
// [role='img'] {
//   filter: invert(100%);
// }
html {
  touch-action: manipulation;
}
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@400;500&family=Source+Serif+Pro&display=swap');
*,
::before,
::after {
  border-color: var(
    --border-color-base
  );
}
:root {
  --measure: 60ch;
  --sticky-offset: 0;
  --stickyBottom-offset: 0;
  --header-height: 50px;
  --leftSideBar-width: 260px;
  --layout-body-background: #fff;

  // theme
  --primary-color: #1da57a; // primary color for all components
  --link-color: #1da57a; // link color
  --text-color: #545454; // major text color
  --text-color-100: #5d5d5d;
  --text-color-200: #6d6d6d;
  --text-color-300: #8c8c8c;
  --border-radius-base: 2px; // major border radius
  --border-color-base: #d9d9d9; // major border color
  --border-color-100: #c7c7c7; // major border color
  --border-color-200: #b9b9b9; // major border color
  --border-color-300: #a9a9a9; // major border color
  --box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

  --background: #fff;
  --background-100: #f9f9f9;
  --background-200: #f3f3f3;
  --background-300: #ededed;
  a:hover {
    color: var(--primary-color);
    text-decoration: underline;
  }
  .ant-layout {
    background: var(--background);
  }
  .ant-breadcrumb a {
    color: var(--text-color);
  }
  .ant-card-bordered {
    border-radius: 0.3em;
    border-color: var(
      --border-color-base
    );
  }
  .ant-breadcrumb-separator {
    color: #f1f1f1;
  }
  .ant-menu,
  .ant-layout-sider {
    background: var(--background-100);
  }
  .ant-select-multiple
    .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-select-dropdown,
  .ant-input-affix-wrapper,
  .ant-picker,
  .ant-picker-panel-container,
  .ant-radio-button-wrapper {
    background: var(--background);
  }
  .ant-btn.ant-btn-icon-only.ant-input-search-button,
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    background: var(--background-100);
    color: var(--text-color);
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: var(--background);
  }
  .ant-select-item-option-selected {
    background: var(--background-200);
  }
  .ant-select-multiple
    .ant-select-selection-item,
  .ant-tag {
    background: var(--background);
  }
  .ant-select-multiple
    .ant-select-selection-item-remove,
  .ant-tag-close-icon {
    color: var(--text-color);
  }
  .RichEditor .toolbar,
  .ant-modal-content {
    background: var(--background);
  }
  .RichEditor
    .public-DraftStyleDefault-pre {
    background: var(--background-100);
  }
  .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab-active,
  .ant-tabs-card
    > div
    > .ant-tabs-nav
    .ant-tabs-tab-active {
    background: var(--background-200);
  }
  .ant-tabs-card
    > .ant-tabs-nav
    .ant-tabs-tab,
  .ant-tabs-card
    > div
    > .ant-tabs-nav
    .ant-tabs-tab,
  .ant-modal-header {
    background: var(--background);
  }
  .ant-result-content {
    background: var(--background);
  }
  .ant-avatar,
  .ant-modal-title,
  .ant-modal-confirm-title,
  .ant-modal-close,
  .ant-card-head,
  .ant-page-header-heading-title,
  .ant-page-header-back-button,
  .ant-menu-item-group-title,
  .ant-result-title {
    color: var(--text-color);
  }
  .SiderMenu-submenu-popup .SiderMenu {
    background: var(--background-100);
  }
  .ant-input,
  .ant-input-number-input {
    background: var(--background);
  }
  .ant-switch {
    background: rgba(
      230,
      230,
      230,
      0.25
    );
  }
  .ant-switch-checked {
    background-color: #1da57a;
  }
  .SiderMenu
    .SiderMenu-item-selected
    .SiderMenu-icon,
  .SiderMenu
    .SiderMenu-item-active
    .SiderMenu-icon {
    background: var(--background);
  }
  .ant-table-thead > tr > th {
    background: var(--background);
    color: var(--text-color);
  }
  .ant-table,
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background: var(--background-100);
    color: var(--text-color);
  }
  .ant-pagination-disabled
    .ant-pagination-item-link,
  .ant-pagination-disabled:hover
    .ant-pagination-item-link,
  .ant-pagination-disabled:focus
    .ant-pagination-item-link {
    color: var(--text-color);
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input-disabled {
    color: var(--border-color-base);
  }
  .ant-pagination-item,
  .ant-pagination-item-link {
    background-color: inherit;
  }
  .ant-table-tbody
    > tr.ant-table-row:hover
    > td {
    background: var(--background);
  }
  .ant-input {
    // background: var(--background-100);
  }
  .SiderMenu-submenu-popup
    .SiderMenu
    .SiderMenu-item-active,
  .ant-tabs-dropdown-menu {
    background: var(--background-200);
  }
  .ant-message-notice-content {
    background: var(--background-200);
  }
  .ant-notification-notice-message,
  .ant-notification-notice-title,
  .ant-notification-notice-description {
    color: rgba(113, 128, 150, 1);
  }
  .ant-drawer-content,
  .ant-drawer-header {
    background: var(--background-100);
  }
  .ant-input-group-addon {
    background: var(--background-100);
  }
  .ant-input[disabled],
  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    // color: rgba(113, 128, 150, 1);
    // opacity: 0.8;
    background: var(--background-100);
  }
  .ant-menu-item-disabled,
  .ant-menu-submenu-disabled {
    color: #cccccc40 !important;
  }

  a:hover {
    color: var(--color-primary);
  }

  .ant-card {
    background: var(--background);
  }

  .ant-dropdown-menu {
    background: var(--background-100);
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background: var(--background-200);
  }

  .ant-modal-mask {
    background-color: rgba(
      234,
      234,
      234,
      0.45
    );
  }

  td.ant-table-column-sort {
    background: var(--background-200);
  }
  .ant-table-thead
    th.ant-table-column-has-sorters:hover {
    background: var(--background-200);
  }
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-item,
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-item
    > a,
  .ant-dropdown-menu-dark
    .ant-dropdown-menu-item
    > .anticon
    + span
    > a {
    color: var(--text-color);
  }
  .ant-breadcrumb {
    color: var(--text-color);
  }
  .ant-spin-container::after {
    background: var(--background);
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: var(--text-color);
  }
  .ant-select-arrow {
    color: var(--text-color);
  }
  .ant-select-item-group {
    color: rgba(113, 128, 150, 1);
  }
  .ant-upload.ant-upload-select-picture-card,
  .ant-upload-list-picture-card
    .ant-upload-list-item-uploading.ant-upload-list-item {
    background: var(--background-200);
  }
  .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background: var(--background-200);
  }
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-card-head {
    border-bottom: 1px solid
      var(--border-color-base);
  }
}

:root {
  --diff: calc(
    var(--max-size) - var(--min-size)
  );
  --responsive: calc(
    (var(--min-size) * 1px) +
      var(--diff) *
      ((100vw - 360px) / 1200)
  );
  --max-size: 17;
  --min-size: 15;
  font-size: var(--responsive);
}

@media screen and (min-width: 1024px) {
  :root {
    font-size: 15px;
  }
}
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: initial;
  vertical-align: initial;
}
html {
  touch-action: manipulation;
}
body {
  overflow-x: hidden;
  // font-size: calc(
  //           13px + (5120px - 300vw) / 1280
  // );
  font-size: 100%;
  overflow-y: scroll;
  // text-rendering: optimizeLegibility;
}
* {
  -webkit-overflow-scrolling: touch;
}

// impotant for not breaking layout

[data-effect='ripple'] {
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(
      circle,
      currentColor 10%,
      transparent 10.01%
    );
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.25s,
      opacity 1s;
  }
  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}
.mobile div {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.verticalList {
  > * + * {
    margin-top: 0.75rem;
  }
  > *:empty {
    margin-top: 0;
  }
  &__small {
    > * + * {
      margin-top: 0.5rem;
    }
  }
  &__medium {
    > * + * {
      margin-top: 1.5rem;
    }
  }
  &__large {
    > * + * {
      margin-top: 2rem;
    }
  }
}

.stickyTop {
  position: sticky;
  top: var(--sticky-offset);
  z-index: 11;
  margin-bottom: -1px;
}
.stickyTop2 {
  position: sticky;
  top: var(--header-height);
  z-index: 11;
  margin-bottom: -1px;
}
.stickyBottom {
  --safe-area-inset-bottom: env(
    safe-area-inset-bottom
  );
  position: sticky;
  bottom: var(--stickyBottom-offset);
  padding-bottom: var(
    --safe-area-inset-bottom
  );
  z-index: 11;
}
.noSrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.noSrollbar::-webkit-scrollbar {
  display: none;
}
.ant-drawer-body {
  background: var(--background);
}
.onHover {
  &:hover {
    .visible {
      visibility: visible;
    }
  }
  .visible {
    visibility: hidden;
  }
  &:hover {
    .visible2 {
      display: block;
    }
  }
  .visible2 {
    display: none;
  }
}
.withHover {
  &:hover
  // ,
  // &:focus 
 {
    .visible {
      visibility: visible !important;
      opacity: 100;
    }
  }
  // &:hover ~ &:focus {
  //   display: none;
  // }
  .visible {
    visibility: hidden !important;
    opacity: 0;
  }
}

.mobile {
  .onHover {
    .visible {
      display: block !important;
    }
  }
}
.onActive {
  &.active {
    .visible {
      display: block !important;
    }
  }
  .visible {
    display: none !important;
  }
}
.disable {
  filter: grayscale(1);
  background: #f1f1f1;
  pointer-events: none !important;
}
.mobile {
  .onActive {
    .visible {
      display: block !important;
    }
  }
}

// body.scrollingDown.mobile
// 	.stickyBottom {
// 	bottom: 0;
// 	bottom: env(safe-area-inset-bottom);
// }

.CustomScrollbar {
  overflow: auto;
}
.CustomScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

.CustomScrollbar::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

.CustomScrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 25%,
    transparent 100%,
    rgba(0, 0, 0, 1) 75%,
    transparent
  );
}
.extend {
  display: none;
  & ~ * {
    display: none;
    &.less {
      display: none;
    }
    &.more {
      display: inherit;
    }
    &:nth-child(2) {
      &.less,
      &.more {
        display: none;
      }
      display: inherit;
    }
    &:nth-child(3) {
      &.less,
      &.more {
        display: none;
      }
      display: inherit;
    }
    &:nth-child(4) {
      &.less,
      &.more {
        display: none;
      }
      display: inherit;
    }
    &:nth-child(5) {
      &.less,
      &.more {
        display: none;
      }
      display: inherit;
    }
    &:nth-child(6) {
      &.less,
      &.more {
        display: none;
      }
      display: inherit;
    }
    &:nth-child(7) {
      &.less,
      &.more {
        display: none;
      }
    }
  }
  &:checked {
    & ~ * {
      display: inherit;
      &.less {
        display: inherit;
      }
      &.more {
        display: none;
      }
    }
  }
}
@mixin dark-mode {
  --success-color: #52c41a; // success state color
  --warning-color: #faad14; // warning state color
  --error-color: #f5222d; // error state color
  --font-size-base: 14px; // major text font size
  --heading-color: rgba(
    0,
    0,
    0,
    0.85
  ); // heading text color
  --text-color: #d2d2d2; // major text color
  --text-color-100: #e5e5e5;
  --text-color-200: #c2c2c2;
  --text-color-300: #888888;
  --border-radius-base: 2px; // major border radius
  --border-color-base: rgb(
    35,
    51,
    66
  ); // major border color
  --border-color-100: #505050;
  --border-color-200: #414141;
  --border-color-300: #5c5c5c;
  --box-shadow-base: rgba(
        38,
        43,
        49,
        0.2
      )
      0px 0px 15px,
    rgba(136, 153, 166, 0.15) 0px 0px
      3px 1px; // major shadow for layers
  --background: rgb(21, 34, 45);
  --background-100: rgb(25, 37, 51);
  --background-200: rgb(28, 42, 57);
  --background-300: rgb(31, 46, 63);
  .border-gray-300 {
    --border-opacity: 0.3;
  }
  .border-gray-200 {
    --border-opacity: 0.3;
  }

  .border {
    --border-opacity: 0.3;
    border-color: var(
      --border-color-base
    );
  }
}
[theme='dark'] {
  @include dark-mode;
}
@media (prefers-color-scheme: dark) {
  [theme='auto'] {
    @include dark-mode;
  }
}
.background {
  background: var(--background);
}
.background-100 {
  background: var(--background-100);
}
.background-200 {
  background: var(--background-200);
}
.hover\:background:hover {
  background: var(--background);
}
.hover\:background-100:hover {
  background: var(--background-100);
}
.hover\:background-200:hover {
  background: var(--background-200);
}

$bar-slug: bar;
$line-slug: line;

.empty {
  text-align: center;
  width: 100%;
}

//.chart {
//  //margin: 25px;
//  position: relative;
//  box-shadow: var(--box-shadow-base);
//  width: 100%;
//  height: 400px;
//  overflow: hidden;
//}
//
//.no-chart-data {
//  background: var(--background-100);
//  min-height: 400px;
//  display: block;
//  width: 100%;
//  overflow: hidden;
//
//  .title {
//    color: var(--text-color);
//    position: absolute;
//    display: flex;
//    left: 50%;
//    top: 50%;
//    margin-right: -50%;
//    transform: translate(-50%, -100%);
//    z-index: 100;
//  }
//
//  .y-axis,
//  .x-axis {
//    position: absolute;
//    left: 25px;
//    bottom: 25px;
//  }
//
//  .y-axis {
//    height: 350px;
//    width: 1px;
//    border-left: 2px solid
//      var(--border-color-base);
//  }
//
//  .x-axis {
//    height: 1px;
//    width: 95%;
//    border-bottom: 2px solid
//      var(--border-color-base);
//  }
//
//  .bars {
//    position: absolute;
//    display: inline-block;
//    bottom: 15px;
//    left: 35px;
//    width: 100%;
//    margin: 0 auto;
//    //overflow: hidden;
//    background: var(--background-100);
//
//    .bar {
//      background: var(--text-color);
//      display: inline-block;
//      position: absolute;
//      min-height: 5px;
//      width: 3%;
//      max-width: 25px;
//      bottom: 10px;
//      margin: 0 0.8%;
//      border-top: 1px solid
//        var(--border-color-base);
//      border-left: 1px solid
//        var(--border-color-base);
//      border-right: 1px solid
//        var(--border-color-base);
//      //display: none;
//
//      @for $i from 0 to 23 {
//        $height: random(350) + 10 + px;
//        &.#{$bar-slug}-#{$i} {
//          height: $height;
//          display: inline-block;
//          margin-left: 1% + $i * 4;
//        }
//      }
//    }
//  }
//
//  .lines {
//    position: absolute;
//    display: inline-block;
//    bottom: 15px;
//    left: 35px;
//    width: 100%;
//    margin: 0 auto;
//    //overflow: hidden;
//    background: var(--text-color);
//
//    .overlay {
//      background: var(--primary-color);
//      display: block;
//      width: 100%;
//      height: inherit;
//    }
//
//    .line {
//      background: var(--background-100);
//      display: inline-block;
//      position: absolute;
//      min-height: 15px;
//      width: 14.28%;
//      bottom: 10px;
//      margin: 0 0.8%;
//      border-top: 1px solid
//        var(--border-color-base);
//      //border-left: 1px solid #FFF;
//      //border-right: 1px solid #FFF;
//      overflow: hidden;
//      //display: none;
//
//      @for $i from 0 to 7 {
//        &.#{$line-slug}-#{$i} {
//          margin-left: 1% + $i * 14.28%;
//        }
//      }
//
//      .line-0 {
//        height: 200px;
//      }
//
//      .line-1 {
//        height: 400px;
//      }
//
//      .slope {
//        display: block;
//        background: var(
//          --background-100
//        );
//        width: 300%;
//        height: 100px;
//        margin-top: -50%;
//        margin-left: -50%;
//
//        &:nth-of-type(1) {
//          -ms-transform: rotate(
//            -25deg
//          ); /* IE 9 */
//          -webkit-transform: rotate(
//            -25deg
//          ); /* Chrome, Safari, Opera */
//          transform: rotate(-25deg);
//        }
//      }
//    }
//  }
//
//  .radial {
//    text-align: center;
//
//    .outer-circle {
//      position: absolute;
//      top: 50%;
//      left: 50%;
//      margin-top: -150px;
//      margin-left: -150px;
//      display: block;
//      background: var(--background-100);
//      width: 300px;
//      height: 300px;
//      border-radius: 50%;
//    }
//
//    .inner-circle {
//      position: absolute;
//      top: 50%;
//      left: 50%;
//      margin-top: -100px;
//      margin-left: -100px;
//      display: block;
//      background: var(--background-100);
//      width: 200px;
//      height: 200px;
//      border-radius: 50%;
//    }
//  }
//}

.masonry-vertical {
  columns: 2;
  column-gap: 16px;
  @media (max-width: 1200px) {
    columns: 2;
  }
  @media (max-width: 992px) {
    columns: 2;
  }
  @media (max-width: 768px) {
    columns: 1;
  }

  .masonry-vertical-grid {
    display: inline-block;
    margin-bottom: 16px;
    position: relative;

    &:before {
      border-radius: 5px;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      //background-color:rgba(0,0,0,.2);
    }
  }
}
.font-serif {
  font-family: 'Noto Serif SC', serif;
}
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
html {
  background: var(--background);
  height: unset;
  // overflow: hidden;
}
body {
  background: var(--background);
  padding: env(
      safe-area-inset-top,
      20px
    )
    env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px)
    env(safe-area-inset-left, 20px);
}
