.SideModal {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 0;
  overflow: hidden;
  z-index: 1000;
  overflow-y: scroll;
}
.SideModalContent {
  position: absolute;
  width: 600px;
  max-width: 100vw;
  background: var(--background);
  min-height: 100%;
  box-shadow: var(--box-shadow-base);
}
.SideModalMark {
  pointer-events: none;
  content: '';
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  border-radius: 0;
  z-index: -1;
  background: #00000085;
}

.SideModal.right .SideModalContent {
  /* left: unset;
	right: 0; */
  right: 0;
}
.SideModal.center .SideModalContent {
  /* left: unset;
	right: 0; */
  left: 50%;
  transform: translateX(-50%);
}

.SideModal.large .SideModalContent {
  width: 800px;
}
.SideModal.full .SideModalContent {
  width: 100%;
}
