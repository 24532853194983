/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  border: none;
  text-align: center;
  align-items: center;
  justify-items: center;
  line-height: 42px;
  white-space: nowrap;
  border-radius: 0.3em;
  font-size: 16px;
  color: #fff;
}

.loginBtn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px
    rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(
    #4c69ba,
    #3b55a0
  );
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('images/icon_facebook.png')
    9px 9px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(
    #5b7bd5,
    #4864b1
  );
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}

.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url('images/icon_google.png')
    9px 9px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}
.content_disabled {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge*/
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/*gradient colors*/

.card {
  height: 8em;
  width: 64em;
  padding: 0.5em 1em;
  display: table-cell;
  vertical-align: middle;
  box-shadow: 0 4px 8px 0
    rgba(0, 0, 0, 0.2);
}

.bg-gradient-f1 {
  background: linear-gradient(
      20deg,
      #f7fdff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    radial-gradient(
      farthest-corner at 0% 0%,
      #ffffff 45%,
      #f6fffc 90%
    );
  background: -webkit-linear-gradient(
      20deg,
      #f7fdff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -webkit-radial-gradient(farthest-corner
          at 0% 0%, #ffffff 45%, #f6fffc
          90%);
  background: -o-linear-gradient(
      20deg,
      #f7fdff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -o-radial-gradient(farthest-corner
          at 0% 0%, #ffffff 45%, #f6fffc
          90%);
  background: -moz-linear-gradient(
      20deg,
      #f7fdff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -moz-radial-gradient(farthest-corner
          at 0% 0%, #ffffff 45%, #f6fffc
          90%);
}

.bg-gradient-f2 {
  background: linear-gradient(
      10deg,
      #fffbfb 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    radial-gradient(
      farthest-corner at 0% 0%,
      #ffffff 45%,
      #fffdf4 90%
    );
  background: -webkit-linear-gradient(
      10deg,
      #fffbfb 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -webkit-radial-gradient(farthest-corner
          at 0% 0%, #ffffff 45%, #fffdf4
          90%);
  background: -o-linear-gradient(
      10deg,
      #fffbfb 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -o-radial-gradient(farthest-corner
          at 0% 0%, #ffffff 45%, #fffdf4
          90%);
  background: -moz-linear-gradient(
      10deg,
      #fffbfb 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -moz-radial-gradient(farthest-corner
          at 0% 0%, #ffffff 45%, #fffdf4
          90%);
}

.bg-gradient-f3 {
  background: linear-gradient(
      10deg,
      #ffffff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    radial-gradient(
      farthest-corner at 0% 0%,
      #f8fffa 45%,
      #ffffff 90%
    );
  background: -webkit-linear-gradient(
      10deg,
      #ffffff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -webkit-radial-gradient(farthest-corner
          at 0% 0%, #f8fffa 45%, #ffffff
          90%);
  background: -o-linear-gradient(
      10deg,
      #ffffff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -o-radial-gradient(farthest-corner
          at 0% 0%, #f8fffa 45%, #ffffff
          90%);
  background: -moz-linear-gradient(
      10deg,
      #ffffff 1%,
      rgba(249, 133, 133, 0) 80%
    ),
    -moz-radial-gradient(farthest-corner
          at 0% 0%, #f8fffa 45%, #ffffff
          90%);
}

.fix-table > table {
  border: 1px solid gray;
  table-layout: fixed;
  min-width: 300px;
}

.fix-table > table > tbody > th,
.fix-table > table > tbody > tr {
  border: 1px solid gray;
  min-width: 160px;
  overflow: hidden;
}

.fix-table > table > tbody > tr > td {
  border: 1px solid gray;
  min-width: 160px;
  padding: 3px;
}

.user-head-menu {
  background-image: radial-gradient(
    ellipse farthest-corner at 0% -90%,
    #00ce22 -90%,
    var(--background-100) 70%,
    var(--background) 70%
  );
  width: 100%;
  display: block;
}

.ant-calendar-picker-input.ant-input.override-calendar-picker {
  font-weight: 900;
}

/* .ant-tabs-tab {
    flex-grow: 1;
    margin-right: 0px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
} */

.hover\:overflow-auto:hover {
  overflow: auto;
}

.tableBorder,
.tableRow,
.oddTableRow,
.tableCell,
.rowNumberCell {
  background-color: var(--background);
}

.headerRow,
.headerCell {
  background-color: var(
    --background-100
  ) !important;
}

.selectedTableRow {
  background-color: var(
    --background-200
  ) !important;
}

.text-color {
  color: var(--text-color);
}

.text-color-100 {
  color: var(--text-color-100);
}

.text-color-200 {
  color: var(--text-color-200);
}

.text-color-300 {
  color: var(--text-color-300);
}

.border-color {
  color: var(--text-color);
}

.border-color-100 {
  color: var(--text-color-100);
}

.border-color-200 {
  color: var(--text-color-200);
}

.border-color-300 {
  color: var(--text-color-300);
}

.sticky-container {
  position: sticky;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: 0;
  min-height: 0;
  min-width: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.masonry-grid {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(320px, 1fr)
  );
  grid-auto-rows: 24px;
}

.masonry-grid-item {
  display: flex;
  align-items: center;
}

.masonry-grid-content {
  overflow: hidden;
  width: 100%;
}

.msg-wrapper {
  position: relative;
  flex: none;
}

.msg-tail-out {
  position: absolute;
  top: 0;
  z-index: 100;
  display: block;
  width: 8px;
  height: 13px;
  color: #dcf8c6;
  right: -8px;
}

.msg-content {
  box-shadow: 0 0px 12px -7px currentColor;
  position: relative;
}

.ant-anchor-link-title {
  white-space: normal !important;
  padding-right: 5px;
}

.clearfix {
  clear: both;
}

.clearfix:after {
  clear: both;
  content: '';
  display: table;
}
