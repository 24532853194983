.userMenu {
	line-height: 1;
	position: absolute;
	right: 2em;
	top: var(--sticky-offset);
	width: 300px;
	overflow-y: hidden;
	background: #fff;
	-webkit-box-shadow: 0px 0px 5px 1px
		rgba(0, 0, 0, 0.33);
	box-shadow: 0px 0px 5px 1px
		rgba(0, 0, 0, 0.33);
	border-radius: 4px;
	max-height: calc(
		100vh - var(--sticky-offset)
	);
}
.navbar-right .action {
	position: relative;
	transition: all 0.3s linear;
	width: 32px;
	height: 32px;
	margin-right: 1.2em;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 16px;
	opacity: 0.8;
	cursor: pointer;
}
.navbar-right .action i {
	font-size: 1.4em;
}
.navbar-right .action:last-child {
	margin-right: 0;
}
.navbar-right .action.last-child {
	margin-right: 0;
}
.navbar-right .action.active {
	background-color: var(
		--color-primary
	);
	color: #fff;
	outline: none;
	box-shadow: 0 0 0 0px
			var(--color-primary),
		0 0 0 2px var(--color-primary);
	opacity: 1;
}
.navbar-right .action.active {
	transform: rotate(45deg);
	border-radius: 16px 16px 0px;
}

.navbar-right .action.signUp,
.navbar-right .action.login {
	width: unset;
	padding: 0 1rem;
	line-height: 32px;
	text-transform: capitalize;
	font-weight: bold;
}
.navbar-right .action.signUp {
	color: #fff;
	background: var(--color-primary);
}
