/* .SettingsLayout {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw !important;
	height: 100vh;
	top: 0;
	max-width: unset !important;
	max-height: unset !important;
}

.SettingsLayout .ant-modal-content {
	max-width: 800px;
	height: 800px;
	width: 100%;
	max-height: calc(
		100vh - 100px - 2rem
	);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	box-shadow: none;
	border-radius: 0;
}
.SettingsLayout .ant-modal-close-icon {
	background: #cecece;
	padding: 0.4em;
	color: #fff;
	border-radius: 100%;
	border: 2px solid #fff;
}
.SettingsLayout
	.ant-modal-content
	.ant-modal-body {
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
	width: 100%;
	padding: 0 1.2rem;
}
@media (max-width: 767px) {
	.SettingsLayout {
		width: 100vw !important;
		height: 100vh;
		align-items: flex-start;
		margin: 0;
		padding: 0;
	}

	.SettingsLayout .ant-modal-content {
		max-width: 100vw;
		max-height: calc(100vh - 100px);
	}
} */
.SettingsLayout {
  position: sticky;
  left: 0;
  padding: 0;
}

.SettingsLayout .ant-modal-content {
  border: none;
  box-shadow: none;
  border-radius: 0;
  max-width: 100vw;
}
.SettingsLayout {
  margin: 0 auto !important;
  width: 900px !important;
  max-width: 100vw;
}
.SettingsLayout.full {
  width: 1200px !important;
}
.SettingsLayout .ant-modal-close-icon {
  background: #cecece;
  padding: 0.4em;
  color: #fff;
  border-radius: 100%;
  border: 2px solid #fff;
}

@media (max-width: 767px) {
  .SettingsLayout .ant-layout-content {
    width: 100vw;
  }
}
