.SiderMenu .ant-menu-submenu-title,
.SiderMenu .ant-menu-item {
  font-size: 1em;
  font-weight: bold;
}

.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 8px;
}
.SiderMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SiderMenu-root .SiderMenu-item {
  width: 100%;
  display: flex;
  justify-content: center;
}
.SiderMenu-root
  .SiderMenu-item
  .SiderMenu-icon,
.SiderMenu-root
  .SiderMenu-submenu-title
  .SiderMenu-icon {
  margin-top: 0.3em;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.SiderMenu-root
  .SiderMenu-item-active
  .SiderMenu-icon,
.SiderMenu-root
  .SiderMenu-submenu-active
  .SiderMenu-icon {
  background: #f1f1f1;
}

.SiderMenu
  .SiderMenu-item-selected
  .SiderMenu-icon {
  background: #f1f1f1;
}
.SiderMenu-inline-collapsed-tooltip
  .SiderMenu-icon {
  display: none;
}
.SiderMenu-label {
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.SiderSubMenu-label {
  font-weight: 500;
  font-size: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.SiderMenu-inline-collapsed
  .SiderMenu-item
  .SiderMenu-label,
.SiderMenu-inline-collapsed
  .SiderMenu-submenu-title
  .SiderMenu-label {
  display: none;
}
.SiderMenu-submenu-hidden {
  display: none;
}
.SiderMenu-submenu-popup {
  z-index: 1050;
  /* left: 83px !important; */
  max-width: 200px;
}
.SiderMenu-submenu-popup .SiderMenu {
  background: #fff;
  border-radius: 0.3em;
  box-shadow: 0px 0px 6px #cecece;
  padding: 0.5em 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.SiderMenu-submenu-popup
  .SiderMenu-icon {
  display: flex;
  align-items: center;
  margin-right: 0.5em;
}
.SiderMenu-submenu-popup
  .SiderMenu
  .SiderMenu-item {
  padding: 0.4rem 0.6rem;
  display: flex;
  align-items: center;
}
.SiderMenu-submenu-popup
  .SiderMenu
  .SiderMenu-item-active {
  background: #f1f1f1;
}
.SiderMenu-submenu-popup .SiderMenu i {
  margin-right: 0.6rem;
}
.SiderMenu-item-divider {
  margin-top: 0.3em;
  width: 70%;
  --border-opacity: 0.3;
  border-bottom-width: 1px;
  border-color: #e2e8f0;
  border-color: rgba(
    226,
    232,
    240,
    var(--border-opacity)
  );
}
.SiderMenu-hidden {
  display: none;
}
