.BaseItemWrap {
	overflow: hidden;
	position: relative;
	width: 256px;
	height: 284px;
	background: #f7fafc;
	border-radius: 0.3em;
}
.BaseItemCover {
	top: 0px;
	left: 0px;
	position: absolute;
	width: 100%;
	height: 144px;
	object-fit: cover;
}
.BaseItemCoverBackground {
	top: 0px;
	left: 0px;
	position: absolute;
	width: 100%;
	height: 145px;
	object-fit: cover;
	background-image: linear-gradient(
		rgba(255, 255, 255, 0) 20%,
		#f7fafc 100%
	);
}
.BaseItemContent {
	display: flex;
	flex-direction: column;
	bottom: 0px;
	left: 0px;
	position: absolute;
	width: 100%;
	padding: 0.9em;
}
.BaseItemAvatar {
	margin-right: 1em;
	background: rgb(255, 255, 255);
	color: rgb(84, 84, 84);
	font-weight: bold;
}
.BaseItemTitle {
	text-overflow: ellipsis;
}
.BaseItemDescription {
	overflow: hidden;
	max-height: 2.3em;
	min-height: 2.3em;
	flex: 1 1 0%;
	text-overflow: ellipsis;
}
.horizontal.BaseItemWrap {
	overflow: hidden;
	position: relative;
	width: 320px;
	height: 200px;
	background: rgb(218, 218, 218);
	border-radius: 0.3em;
	.BaseItemCover {
		top: 0px;
		right: 0px;
		left: unset;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.BaseItemCoverBackground {
		top: 0px;
		right: 0px;
		left: unset;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		background-image: linear-gradient(
			45deg,
			rgba(218, 218, 218, 1) 0%,
			rgba(218, 218, 218, 1) 42%,
			rgba(218, 218, 218, 0.6) 65%,
			rgba(218, 218, 218, 0) 100%
		);
	}
	.BaseItemContent {
		display: flex;
		flex-direction: column;
		bottom: 0px;
		left: 0px;
		position: absolute;
		width: 100%;
		padding: 0.9em;
	}
	.BaseItemAvatar {
		margin-right: 1em;
		background: rgb(255, 255, 255);
		color: rgb(84, 84, 84);
		font-weight: bold;
	}
	.BaseItemTitle {
		text-overflow: ellipsis;
	}
	.BaseItemDescription {
		overflow: hidden;

		flex: 1 1 0%;
		font-weight: 500;
		text-overflow: ellipsis;
	}
}
