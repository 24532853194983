.BottomBar {
  pointer-events: none;
  position: relative;
  padding-bottom: 0;
  padding-bottom: env(
    safe-area-inset-bottom
  );
  z-index: 20;
  width: 100%;
  display: block;
  /* overflow: hidden; */
  background: var(--background);
}
.BottomBar > nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  pointer-events: auto;
  padding-bottom: 0;
  padding-bottom: env(
    safe-area-inset-bottom
  );
  padding-left: 0;
  padding-left: env(
    safe-area-inset-left
  );
  padding-right: 0;
  padding-right: env(
    safe-area-inset-right
  );
}
/* .BottomBar.mobile {
   transform: translateY(0%); 
} */

/* body.scrollingDown.mobile .BottomBar {
	transform: translateY(
		calc(
			env(safe-area-inset-bottom) + 50px
		)
	);
} */
.BottomBarItem {
  flex-direction: column;
  pointer-events: auto;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 19%;
}
.BottomBarItem svg {
  transform: translate3d(0, 0, 0);
}
.BottomBarItem_label {
  height: 1em;
  font-size: 2vw;
  line-height: 1;
  /* transition: height 0.25s linear; */
}
.BottomBarItem_icon {
  font-size: 1.6rem;
  color: currentColor;
}
.BottomBarItem__active {
  color: var(--color-primary);
}
/* .BottomBarItem__active
  .BottomBarItem_icon {
   font-size: 1.5em; 
} */
.BottomBarItem__active
  .BottomBarItem_label {
  height: 1em;
}
@media (min-width: 480px) {
  .BottomBarItem_label {
    font-size: 0.7rem;
  }
}
