.loginSocialButton {
  display: block;
  width: 100%;
  text-align: center;
  min-height: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #fff !important;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: 4px;
}
.loginSocialButton.facebook {
  background-color: #3b5998;
  color: #fff !important;
}
.loginSocialButton.google {
  background-color: #ec1e11;
  color: #fff !important;
}
