.FeedInIcon {
  height: 30px;
}
.accfb2db-bc26-4385-b7e4-c20a3348e16e {
  fill: #97c33d;
}
.\33 ad42ba2-cc2c-4cf8-918c-47a449befec4 {
  fill: #459dd6;
}
.\34 ca0fe7b-31d6-408e-8a17-973a75262386 {
  fill: #00a551;
}
.FeedInIcon.loading {
  overflow: visible !important;
}
.FeedInIcon.loading > * {
  fill: #ceceec;
}

.loading path {
  transform: translate(-27.3px, -190.3px);
  animation: FeedInIconLoading 1s ease-in-out infinite;
}
.loading .pathOne {
  animation-delay: 0s;
}
.loading .pathTwo {
  animation-delay: 0.2s;
}
.loading .pathThree {
  animation-delay: 0.4s;
}
.loading .pathFour {
  animation-delay: 0.6s;
}
.loading .pathFive {
  animation-delay: 0.8s;
}
.loading .pathSix {
  animation-delay: 1s;
}

@keyframes FeedInIconLoading {
  0% {
    transform: translate(-27.3px, -190.3px);
  }

  33% {
    transform: translate(-27.3px, -210.3px);
  }
  100% {
    transform: translate(-27.3px, -190.3px);
  }
}
@keyframes FeedInIconFill {
  0% {
    fill: #cecece;
  }
  33% {
    fill: initial;
  }
  100% {
    fill: #cecece;
  }
}
