html {
  --FeedCount-animation: rubberBand;
}
.FeedCount * {
  color: #718096;
}
.FeedCount:hover * {
  color: currentColor;
}
.FeedCount.active * {
  color: currentColor;
}
.FeedCountButton {
  position: relative;
  display: inline-flex;
  line-height: unset;
  align-items: center;
}
.FeedCountIcon {
  font-size: 1.2em;
}
.FeedCountButton:focus {
  outline: none;
}
.FeedCountButton:active {
  outline: none;
}
/* .FeedCountButton:before {
} */
.FeedCountButton:before {
  content: '';
  opacity: 0;
  transform: scale(0.9);
  transition: transform 0.15s ease-in,
    opacity 0.15s ease-in;
}
.FeedCountButton:focus:before,
.FeedCountButton:hover:before {
  position: absolute;
  top: -0.4em;
  left: -0.4em;
  right: -0.4em;
  bottom: -0.4em;
  transform: scale(1);
  background: currentColor;
  border-radius: 99em;
  background: currentColor;
  opacity: 0.2;
}

.FeedCountButton:active:before {
  animation: var(
    --FeedCount-animation
  ); /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.25s;
}
