.wrapper {
  position: absolute;
  width: 0;
  height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
  z-index: 10;
  > div {
    transform: translateY(14px);
  }
  .blockType {
    box-sizing: border-box;
    border: 1px solid #ddd;
    background: #fff;
    padding: 5px;
    margin: 0;
    border-radius: 18px;
    cursor: pointer;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    svg {
      fill: #888;
    }
  }
  .spacer {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 8px;
  }

  .popup {
    position: absolute;
    left: 100%;
    background: #efefef;
    border: 1px solid #ddd;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px
      rgba(220, 220, 220, 1);
    z-index: 3;
    box-sizing: border-box;
    margin-top: 8px;
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(
        251,
        251,
        251,
        0
      );
      border-bottom-color: #fbfbfb;
      border-width: 4px;
      margin-left: -4px;
    }
    &:before {
      border-color: rgba(
        221,
        221,
        221,
        0
      );
      border-bottom-color: #ddd;
      border-width: 6px;
      margin-left: -6px;
    }
  }
  .sidebuttonwrap button {
    display: flex;
  }
}
.buttonScalableGroup.is-scaled
  .button--scale {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
.button--scale {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,
    -webkit-transform;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.u-transitionSeries:nth-child(2) {
  -webkit-transition-delay: 30ms !important;
  transition-delay: 30ms !important;
}
.u-transitionSeries:nth-child(3) {
  -webkit-transition-delay: 60ms !important;
  transition-delay: 60ms !important;
}
.u-transitionSeries:nth-child(4) {
  -webkit-transition-delay: 90ms !important;
  transition-delay: 90ms !important;
}
.button--inlineTooltipControl {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform
    0.1s;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s,
    -webkit-transform 0.1s;
}
.is-scaled
  > .button--inlineTooltipControl {
  -webkit-transition: -webkit-transform
    250ms;
  transition: -webkit-transform 250ms;
  transition: transform 250ms;
  transition: transform 250ms,
    -webkit-transform 250ms;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
