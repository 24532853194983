.draftJsFocusPlugin__unfocused__1Wvrs:hover {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #d2e3f7;
  z-index: 10;
}
.draftJsFocusPlugin__unfocused__1Wvrs {
  z-index: 10;
  position: relative;
  clear: both;
}
.draftJsFocusPlugin__focused__3Mksn {
  cursor: default;
  border-radius: 2px;
  box-shadow: 0 0 0 3px #515e6d;
  z-index: 10;
  clear: both;
}
