.ShadowScrollbar {
  width: 100%;
  height: 100%;
}
.ShadowScrollbar:hover
.ShadowScrollbarTrack {
  opacity: 1;
  right: 0px;
  width: 10px !important;
}

.ShadowScrollbar:hover
.ShadowScrollbarThumb,
.ShadowScrollbar.onScroll
.ShadowScrollbarThumb {
  opacity: 1;
}

.color--white--opacity {
  color: rgba(255, 255, 255, 0.85);
}

.ShadowScrollbarTrack {
  opacity: 1;
  position: absolute;
  width: 4px !important;
  top: 0;
  bottom: 0;
  right: -3px;
  background: transparent;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  padding: 10px 0;
}

.ShadowScrollbar
.ShadowScrollbarTrack:hover,
.ShadowScrollbar.onScroll
.ShadowScrollbarTrack {
  width: 20px !important;
}
.ShadowScrollbarThumb {
  opacity: 0.5;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.12);
  -webkit-transition: opacity 0.05s linear;
  transition: opacity 0.05s linear;
}


/*!* width *!*/
/*.ScrollbarTrack::-webkit-scrollbar {*/
/*  width: 8px;*/
/*  !*opacity: 0.5 !important;*!*/
/*}*/

/*!* Track *!*/
/*.ScrollbarTrack::-webkit-scrollbar-track {*/
/*  background: var(--background);*/
/*}*/

/*!* Handle *!*/
/*.ScrollbarTrack::-webkit-scrollbar-thumb {*/
/*  background: var(--background-100);*/
/*  border: 0.5px solid transparent;*/
/*  border-radius: 5px;*/
/*  background-clip: content-box;*/
/*  opacity: 0.5 !important;*/
/*}*/

/*!* Handle on hover *!*/
/*.ScrollbarTrack::-webkit-scrollbar-thumb:hover {*/
/*  background: var(--background-200);*/
/*}*/
